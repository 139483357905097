import React, { SetStateAction } from "react"
import { AxiosResponse } from "axios"
import { trackPromise } from "react-promise-tracker"
import Swal from "sweetalert2"

export const cachearContexto = (chave: any, setStateChave: React.Dispatch<SetStateAction<any>>, promise: () => Promise<AxiosResponse<any>>) => {
    if(chave?.length) {
        return 1
    } else {
        trackPromise(promise()
            .then(response => setStateChave(response.data))
            .catch((erro: any) => {
                Swal.fire('Ooops...', `Houve um erro ao tentar cachear o contexto na chave ${chave}, promise: ${promise}. Status: ${erro.status}, ${erro.response?.data?.message}. ${erro}`)
            })
        )
        return 1
    }
}
