import crm from "./crm";

const TicketStatus = {
  index: () => {
    return crm.get("/ticket-status");
  },
  buscarStatusPorFluxoTicket: (idTicket) => {
    return crm.get(`/ticket-status/ticket/${idTicket}`);
  },
  show: (id) => {
    return crm.get("/ticket-status/" + id);
  },
  updade: (dados, id) => {
    let uri = "/ticket-status/" + id;
    return crm["put"].call(crm, uri, { status: dados });
  },
  store: (dados) => {
    let uri = "/ticket-status";
    return crm["post"].call(crm, uri, { status: dados });
  },
  delete: (id) => {
    return crm.delete("/ticket-status/" + id);
  },
  buscarCountStatus: (idStatus, filtros) => {
    return crm.get("/ticket/getTotalCountStatus/" + idStatus , {
      params: filtros,
    });
  },
};

export default TicketStatus;
