const urlHelper = {
  download: (link) => {
    let url;
    let linkOrigem;
    url = link.split("/");
    linkOrigem = url[2].split(".");
    if (linkOrigem[1] === "dommusdriver") {
      window.open(urlHelper.validaHttps(link));
    }else if (linkOrigem[0] === "dommus-php" && linkOrigem[2] === "amazonaws") {
      let fileName;
      fileName = link.split("/").slice(-1)[0];

      let form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", `https://arquivos.dommus.com.br/${fileName}`);
      form.setAttribute("target", "_blank");
      let input = document.createElement("input");
      input.type = "hidden";
      input.name = "file";
      input.value = link;
      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
    } else {
      window.open(link);
    }
  },
  validaHttps: (url) => {
    if (url.match(/https/gi)) {
      return(url);
    } else {
      var newstr = url.replace(
        /http/gi,
        "https"
      );
      return(newstr);
    }
  },
  alterarUrlArquivoComNomeOriginal: (url, nomeArquivoOriginal, nomeArquivoS3=undefined) => {
    if(!url || !nomeArquivoOriginal) {
      return url || null
    }
    const dadosUrl = new URL(url)
    const anexoPrivado = dadosUrl.host == 'arquivos.dommus.com.br'
    if(!nomeArquivoS3 && !anexoPrivado) {
      return null
    }
    if(anexoPrivado) {
      const arquivoS3 = dadosUrl.pathname.split('/').filter(segmento => segmento)
      const nomeAnexoS3 = arquivoS3.pop()
      url = `${dadosUrl.origin}/${arquivoS3.join('/')}/${nomeArquivoOriginal}?a=${btoa(nomeAnexoS3)}`
    } else {
      url = `https://publico.dommus.com.br/${nomeArquivoOriginal}?a=${btoa(nomeArquivoS3)}`
    }
    return url
  }
};

export default urlHelper;
