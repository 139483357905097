import React, { createContext } from 'react';
import formatDommus from '../../helpers/format';
import Pusher from 'pusher-js';
import { PusherContextProviderType, PusherContextType } from './types';
declare global{
  var pusher:any
}
export const PusherContext = createContext<PusherContextType | null>( null );
export const PusherContextProvider = ({children}: PusherContextProviderType) => {
    const idInquilino = localStorage.getItem('@DommusCRM-idInquilino')
    const pusherChannel = "desk-inquilino-"+formatDommus.zeroPad(Number(idInquilino), 4)
    if(!(global.pusher)){
        global.pusher = new Pusher('ZWmsypUbT9C', {
            wsHost: 'wsdommus.dommus2.com.br',
            wssPort: 6001,
            forceTLS: true,
            enabledTransports: ['ws', 'wss']
        });
    }
    const pusherState = global.pusher?.subscribe(pusherChannel)
    
    return(
        <PusherContext.Provider value={{pusherState, pusherChannel}}>
            {children}
        </PusherContext.Provider>
    )
}
