import React from 'react';
import {Modal} from 'react-bootstrap';
import './index.css';

export default function ModalDommus({children, ...props}) {

	return <Modal show={props.open} onHide={props.close} size={props.size || "lg"}
								centered
								className={props?.className}
								style={{
									zIndex: '1040',
									paddingRight: '17px',
									display: 'block',
								}}
                                backdrop={props?.backdrop ?? true}
                                enforceFocus={false}
            >
		<Modal.Header closeButton>
			<Modal.Title className="pt-2"><h2>{props.titulo}</h2></Modal.Title>
		</Modal.Header>
		<Modal.Body 
          style={{
            background: props?.background ?? 'white',
          }}
        >
			<div className={"conteudo"}>
				{props.content}
				{children}
			</div>
		</Modal.Body>
	</Modal>;
}
