import React, { createContext, ReactElement, useState, Dispatch, SetStateAction, useEffect } from "react";
import { TicketSubstatusType } from "../../types/TicketSubstatusType";
import { TicketEtapaType } from "./types";

interface TicketEtapaContextProps {
  ticketEtapaSelecionado: TicketEtapaType;
  setTicketEtapaSelecioando: Dispatch<SetStateAction<TicketEtapaType>>;
  ticketStatusSelecionado: TicketSubstatusType;
  setTicketStatusSelecionado: Dispatch<SetStateAction<TicketSubstatusType>>;
  listaTicketEtapa: TicketEtapaType[];
  setListaTicketEtapa: Dispatch<SetStateAction<TicketEtapaType[]>>;
  listaTicketStatus: TicketSubstatusType[];
  setListaTicketStatus: Dispatch<SetStateAction<TicketSubstatusType[]>>;
  openModalEtapa: boolean;
  setOpenModalEtapa: Dispatch<SetStateAction<boolean>>
  nomeFiltrar: string;
  setNomeFiltrar: Dispatch<SetStateAction<string>>
  handleAdicionarTicketEtapa: (ticketEtapa:TicketEtapaType) => void;
  handleAtualizarTicketEtapa: (idTicketStatus:number, ticketEtapa:TicketEtapaType) => void;
  handleRemoveTicketEtapa: (idTicketStatus:number) => void;
  resolverNomeTipo: (sigla:string) => string | null;
  resolverNomeEtapa: (idCampo:string|number) => string | null;
  handleAdicionarTicketStatus: (ticketStatus:TicketSubstatusType) => void;
  handleAtualizarTicketStatus: (idTicketSubstatus:number, ticketStatus:TicketSubstatusType) => void;
  handleRemoveTicketStatus: (idTicketSubstatus:number) => void;
}

export const TicketEtapaContext = createContext({} as TicketEtapaContextProps);

export function TicketEtapaProvider({ children }: { children: ReactElement }) {
  const [ticketEtapaSelecionado, setTicketEtapaSelecioando] = useState<TicketEtapaType>({} as TicketEtapaType) 
  const [ticketStatusSelecionado, setTicketStatusSelecionado] = useState<TicketSubstatusType>({} as TicketSubstatusType) 
  const [listaTicketEtapa, setListaTicketEtapa] = useState<TicketEtapaType[]>([])
  const [listaTicketStatus, setListaTicketStatus] = useState<TicketSubstatusType[]>([]) 
  const [openModalEtapa, setOpenModalEtapa] = useState(false);
  const [mapaTicketEtapas, setMapaTicketEtapas] = useState<Map<number, TicketEtapaType>>();
  const [nomeFiltrar, setNomeFiltrar] = useState("");

  useEffect(()=>{
    const novoMapa = new Map();
    listaTicketEtapa.forEach((campo:TicketEtapaType) => {
      novoMapa.set(Number(campo.id_ticket_status), campo);
    });
    setMapaTicketEtapas(novoMapa);
  }, [listaTicketEtapa]);

  const handleAdicionarTicketEtapa = (ticketEtapa:TicketEtapaType)=>{
    setListaTicketEtapa((current) => [ticketEtapa, ...current]);
  }

  const handleAtualizarTicketEtapa = (idTicketStatus:number, ticketEtapa:TicketEtapaType) => {
    setListaTicketEtapa((current) => {
      return current.map((item) => {
         if(item?.id_ticket_status === idTicketStatus){
           return ticketEtapa;
         }
         return item;
      })
    })
  }

  const handleRemoveTicketEtapa = (idTicketStatus:number) => {
    setListaTicketEtapa((current) => current.filter((item) => item.id_ticket_status !== idTicketStatus));
  }

  const resolverNomeEtapa = (idCampo:number|string, padrao = "Não Atribuído") => {
    let campo = mapaTicketEtapas?.get(Number(idCampo));
    return (campo && campo?.nome) || padrao;
  };

  const handleAdicionarTicketStatus = (ticketSubstatus:TicketSubstatusType)=>{
    setListaTicketStatus((current) => [ticketSubstatus, ...current]);
  }

  const handleAtualizarTicketStatus = (idTicketSubstatus:number, ticketSubstatus:TicketSubstatusType) => {
    setListaTicketStatus((current) => {
      return current.map((item) => {
         if(item?.id_ticket_substatus === idTicketSubstatus){
           return ticketSubstatus;
         }
         return item;
      })
    })
  }

  const handleRemoveTicketStatus = (idTicketSubstatus:number) => {
    setListaTicketStatus((current) => current.filter((item) => item.id_ticket_substatus !== idTicketSubstatus));
  }

  const resolverNomeTipo = (sigla:string)=>{
    switch(sigla){
      case 'A':
        return "Atrasado";
      case 'F':
        return "Conclusão";
      case 'R':
        return "Reaberto";
      case 'C':
        return "Retorno do cliente";
      case 'E':
        return "Aguardando retorno do cliente";
      case 'AU':
        return "Autorização";
      case 'EX':
        return "Execução";
      case 'AP':
        return "Aprovação";
      case 'Cancelado':
        return "CA";
      default:
        return null;
    }
  }

  return (
      <TicketEtapaContext.Provider
        value={{
          ticketEtapaSelecionado,
          setTicketEtapaSelecioando,
          listaTicketEtapa,
          setListaTicketEtapa,
          handleAdicionarTicketEtapa,
          handleAtualizarTicketEtapa,
          handleRemoveTicketEtapa,
          openModalEtapa, 
          setOpenModalEtapa,
          resolverNomeTipo,
          listaTicketStatus,
          setListaTicketStatus,
          resolverNomeEtapa,
          ticketStatusSelecionado,
          setTicketStatusSelecionado,
          handleAdicionarTicketStatus,
          handleAtualizarTicketStatus,
          handleRemoveTicketStatus,
          nomeFiltrar,
          setNomeFiltrar
        }}
      >
          {children}
      </TicketEtapaContext.Provider>
  );
}
