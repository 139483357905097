import crm from "./crm";

const TicketStatus = {
  index: () => {
    return crm.get("/ticket-substatus");
  },
  show: (id) => {
    return crm.get("/ticket-substatus/" + id);
  },
  updade: (dados, id) => {
    let uri = "/ticket-substatus/" + id;
    return crm["put"].call(crm, uri, { substatus: dados });
  },
  store: (dados) => {
    let uri = "/ticket-substatus";
    return crm["post"].call(crm, uri, { substatus: dados });
  },
  delete: (id) => {
    return crm.delete("/ticket-substatus/" + id);
  },
};

export default TicketStatus;
